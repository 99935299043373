'use client';

import { Modal, Spinner } from 'flowbite-react';

export function ConfirmModal ({isOpen,setIsOpen, confirm, isPending}: {isOpen:boolean, setIsOpen:(value: boolean) => void , confirm: () => void, isPending: boolean}) { 
    return(
        <>
            <Modal show={isOpen} size={'sm'} position={'center'} dismissible onClose={() => setIsOpen(false)}>
                <div className='p-4'>
                    <p className='font-bold text-xl text-fp-dark-gray'>Attention:</p>
                    <p className='mt-4 text-fp-gray'>There are items currently in your cart.<br/>Do you wish to replace them?</p>
                </div>
                {isPending ? <div className='flex w-full text-center justify-center pb-4'><Spinner aria-label="Large spinner" size="lg" /></div> : 
                    <div className='flex gap-2 border-t-[1px] border-fp-gray w-full px-4 pb-4' >
                        <button onClick={()=>setIsOpen(false)} className="px-6 flex items-center flex-nowrap phone-button hover:bg-fp-green bg-white border-fp-green border-2 min-w-32 min-h-10 hover:text-white text-fp-green mt-4 w-full justify-center p-4 font-bold">
                            No
                        </button>
                        <button onClick={confirm} className="px-6 flex items-center flex-nowrap phone-button bg-fp-green hover:bg-white hover:border-fp-green border-2 min-w-32 min-h-10 text-white hover:text-fp-green mt-4 w-full justify-center p-4 font-bold">
                            Yes
                        </button>
                    </div>
                }
            </Modal>            
      </>
    )
}