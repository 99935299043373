
const Price = ({
  amount,
  className,
  currencyCode = 'USD',
  currencyCodeClassName,
  discount = 0
}: {
  amount: string;
  className?: string;
  currencyCode: string;
  currencyCodeClassName?: string;
  discount?: number;
} & React.ComponentProps<'p'>) => (
  <p suppressHydrationWarning={true} className={className}>
    {`${new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'narrowSymbol'
    }).format(parseFloat(amount) )}`}
  </p>
);

export default Price;
