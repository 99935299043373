
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"1914667da54a2d0a5697950f2042f00b7aa3c660":"siteVerification"} */ export var siteVerification = createServerReference("1914667da54a2d0a5697950f2042f00b7aa3c660");
import { createServerReference } from "private-next-rsc-action-client-wrapper";

