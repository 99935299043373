'use client';
import { Menu } from 'lib/shopify/types';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

interface NavbarLinkProps {
  item: Menu;
}

export const NavbarLink = (props: NavbarLinkProps) => {
  const [isSelected, setIsSelected] = useState(false);
  const pathname = usePathname();
  const { item } = props;

  useEffect(() => {
    setIsSelected(!item.path.localeCompare(pathname));
  }, [pathname]);

  return (
    <div>
      <Link
        href={item.path}
        prefetch={true}
        className={`text-sm xl:text-lg text-fp-gray underline-offset-4 hover:text-fp-green dark:text-neutral-400 dark:hover:text-neutral-300 ${isSelected ? 'text-fp-green underline underline-offset-8' : ''}`}
      >
        {item.title}
      </Link>
    </div>
  );
};
