'use client';

import { Transition, TransitionChild } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import Form from 'next/form';
import { useSearchParams } from 'next/navigation';
import { Fragment, useState } from 'react';
import { cn } from 'utils/cn';
import { SearchButton } from '../cta-navbar/search-button';

export default function Search() {
  const searchParams = useSearchParams();
  const [toggle, setToggle] = useState(true);  

  return (
    <div className={cn('flex overflow-hidden transition transform ease-in-out duration-[1500ms]', {'bg-white rounded-lg': toggle})}>
      <Transition show={toggle}>
        <TransitionChild
          as={Fragment}
          enter="transition-all ease-in-out duration-[1500ms]"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition-all ease-in-out duration-[1500ms]"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
          afterLeave={() => setToggle(false)}
        >
          <Form action="/search" className="w-max-[550px] relative w-full lg:w-80 xl:w-full">
            <input
              key={searchParams?.get('q')}
              type="text"
              name="q"
              placeholder="Search for products..."
              autoComplete="off"
              defaultValue={searchParams?.get('q') || ''}
              className="text-md w-full rounded-lg border-none focus:outline-none focus:ring-0 focus:border-none bg-white px-4 py-2 text-black placeholder:text-neutral-500 md:text-sm dark:border-neutral-800 dark:bg-transparent dark:text-white dark:placeholder:text-neutral-400"
              />
          </Form>
        </TransitionChild>
      </Transition>
      <button className={cn('z-50', {'bg-white': toggle})} onClick={()=> setToggle(!toggle)}>
        <SearchButton/>
      </button>
    </div>
  );
}

export function SearchSkeleton() {
  return (
    <form className="w-max-[550px] relative w-full lg:w-80 xl:w-full">
      <input
        placeholder="Search for products..."
        className="w-full rounded-lg border bg-white px-4 py-2 text-sm focus:border-none focus:ring-0 focus:outline-none text-black placeholder:text-neutral-500 dark:border-neutral-800 dark:bg-transparent dark:text-white dark:placeholder:text-neutral-400"
      />
      <div className="absolute right-0 top-0 mr-3 flex h-full items-center">
        <MagnifyingGlassIcon className="h-4" />
      </div>
    </form>
  );
}
