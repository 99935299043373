'use client';
import { Turnstile } from '@marsidev/react-turnstile';
import { generateLeadFormURL } from 'app/actions/affiliate.actions';
import { siteVerification } from 'app/actions/cloudflare.actions';
import { getTurnstileKey } from 'app/actions/turnstile.actions';
import { Modal } from 'flowbite-react';
import Link from 'next/link';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { AffiliateList } from 'types/builder';

type DataProps = {
  link: string;
  heading: string;
  excludedRoutes: {
    route: string;
  }[];
};

interface DynamicForm {
  [key: string]: string;
}

export function TopBannerSegmentItem({
  data,
  affiliateList
}: {
  data: DataProps;
  affiliateList: AffiliateList | undefined;
}) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(true);
  const [showThankYou, setShowThankYou] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [turnstileKey, setTurnstileKey] = useState<string | null>(null);
  const [form, setForm] = useState<DynamicForm>({
    gaMedium: 'referral',
    gaSource: '',
    gaContent: '/',
    gaClientId: '970385394.1727054325',
    gaCampaign: '(referral)'
  });

  useEffect(() => {
    setTurnstileKey(getTurnstileKey());
  }, []);

  const pathname = usePathname();
  const { link, heading, excludedRoutes } = data;
  const flatRoutes = excludedRoutes.map((route) => route.route);

  useEffect(() => {
    const isAffiliate = affiliateList?.includes(pathname);
    const isExcluded = flatRoutes.some((route) => pathname.includes(route));
    isAffiliate || isExcluded ? setShow(false) : setShow(true);
  }, [pathname]);

  if (!show) return null;

  async function createQuote(turnstileToken: string) {
    if (!turnstileToken) return;

    try {
      const status = await siteVerification(turnstileToken);

      if (status === 200) {
        const rawFormData = {
          email
        };

        const payload = {
          contact: {
            isResidential: true,
            email
          },
          compliance: {
            tcpaPermissionGranted: true,
            tcpaPermissionLink: ''
          },
          additionalFields: {
            EMAIL: email,
            ...form
          } as any
        };

        const googleclickid = searchParams?.get('gclid');
        if (googleclickid) {
          payload.additionalFields['googleclickid'] = googleclickid;
        }

        const subid = searchParams?.get('subid');
        if (subid) {
          payload.additionalFields['subid'] = subid;
        }

        const leadURL = await generateLeadFormURL();

        if (leadURL) {
          const response = await fetch(leadURL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
          });
          if (response.status === 200) {
            setEmail('');
            setShowThankYou(true);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="container mx-auto !px-0">
      <div className="flex w-full flex-col items-center justify-between bg-fp-green py-3 md:flex-row lg:py-1">
        <div className="py:5 px-6 py-1 text-white">
          <h2 className="text-center text-[18px] font-medium md:text-left">
            <Link href={link}>{heading}</Link>
          </h2>
        </div>
        <div className="flex h-full min-h-[140px] flex-col justify-center text-white md:py-2 lg:mr-10 lg:min-h-[80px] lg:min-w-[300px]">
          {!showThankYou && (
            <>
              <div className="flex w-full flex-col items-center pr-3 md:items-start lg:flex-row lg:items-center">
                <form>
                  <input
                    type="email"
                    className="text-gray-900"
                    name="email"
                    value={email}
                    placeholder="Enter your email address"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  
                </form>
                <button
                  className="mt-4 underline lg:ml-3 lg:mt-0"
                  onClick={() => {
                    if (email) {
                      setIsOpen(true);
                      setMessage('');
                    }else{
                      setMessage('Please enter a valid email address');
                    }
                  }}
                >
                  Get Offer
                </button>
              </div>
              {message && message !== '' && 
                <div className="mt-1 bg-white w-fit rounded-sm">
                    <span className='text-[10px] text-red-700 mx-2'>{message}</span>
                </div>
              }
              <p className="mt-1 text-[10px]">
                Get offer plus promotions and updates. Review our{' '}
                <Link className="underline" href={'/privacy-policy'}>
                  {' '}
                  privacy policy.
                </Link>
              </p>              
            </>
          )}

          {showThankYou && (
            <div className="mr-10 flex flex-col items-center text-nowrap">
              <button
                className="bg-[#fff] px-1 font-medium text-black"
                onClick={() => {
                  setShowThankYou(false);
                  router.push('/shop');
                }}
              >
                Shop now
              </button>
              <p className="mt-1 text-[10px]">Thank You.</p>
            </div>
          )}
        </div>
      </div>
      <Modal show={isOpen} size="sm" onClose={() => setIsOpen(false)}>
        <Modal.Body>
          {turnstileKey && (
            <Turnstile
              siteKey={turnstileKey}
              onSuccess={(e) => {
                setIsOpen(false);
                createQuote(e);
              }}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
