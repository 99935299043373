export function slugToName(slug: string) {
  return slug
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

export function nameToSlug(name: string) {
  return name.toLowerCase().split(" ").join("-")
}

export function typeToName(type: string) {
  return type.toLowerCase().replace(/_/g, ' ')
}

export function typeToHandle(type: string) {
  return type.toLowerCase().replace(/_/g, '-')
}

//Replace all underscores with spaces and leave capitalize the first letter of each word
export function typeToTitle (type: string) {
  const name = type.replace(/_/g, ' ')
  return uppercaseToCapitalize(name)
}

export const uppercaseToCapitalize = (string : String) =>
  string.replaceAll(/\S*/g, word =>
   `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
);

export const capitalizeHandle = (handle: string) => {
  return handle.split('-').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join('-')
}