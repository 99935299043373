
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"30274adc98b7aacfbcfba5e9c675bc5c4bd5c62b":"addToCart"} */ export var addToCart = createServerReference("30274adc98b7aacfbcfba5e9c675bc5c4bd5c62b");
import { createServerReference } from "private-next-rsc-action-client-wrapper";

