'use client';
import { useEffect } from 'react';

export function SmartBot() {
  useEffect(() => {
    var id = 'mavenoid-script';
    var script = document.getElementById('pre-' + id);
    if (!document.getElementById(id) && script) {
      var loader = document.createElement('script');
      loader.id = id;
      loader.async = true;
      loader.src =
        'https://widget-hosts.mavenoid.com/custom-embedding-scripts/frontpoint-product-selector.js';
      script?.parentNode?.insertBefore(loader, script);
      window['mavenoid'] = window['mavenoid'] || [];
    }
  }, []);
  return (
    <>
      <div></div>
      <script id="pre-mavenoid-script"></script>
    </>
  );
}
