'use client';

import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { updateItemQuantity } from 'components/cart/actions';
import { useCart } from 'components/cart/cart-context';
import type { CartItem } from 'lib/shopify/types';
import { useRouter } from 'next/navigation';
import { startTransition, useState } from 'react';
import { cn } from 'utils/cn';

function SubmitButton({ type, onClick, disabled}: { type: 'plus' | 'minus', onClick?: () => void , disabled: boolean}) {
  return (
    <button
      type="submit"
      aria-label={type === 'plus' ? 'Increase item quantity' : 'Reduce item quantity'}
      className={clsx(
        'ease flex h-full min-w-[36px] max-w-[36px] flex-none items-center justify-center rounded-full p-2 transition-all duration-200 hover:border-neutral-800 hover:opacity-80',
        {
          'ml-auto': type === 'minus'
        }
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {type === 'plus' ? (
        <PlusIcon className="h-4 w-4 dark:text-neutral-500" />
      ) : (
        <MinusIcon className="h-4 w-4 dark:text-neutral-500" />
      )}
    </button>
  );
}

export function EditItemQuantityButton({
  item,
  type,
  optimisticUpdate
}: {
  item: CartItem;
  type: 'plus' | 'minus';
  optimisticUpdate: any;
}) {
  const router = useRouter();
  const { addCartItem } = useCart();
  const [message, setMessage] = useState('')
  const [isPending, setIsPending] = useState(false)
  const payload = {
    merchandiseId: item.merchandise.id,
    quantity: type === 'plus' ? item.quantity + 1 : item.quantity - 1
  };
  const checkIfQuantityIsZero = item.quantity === 0;
  const addToCartTransition = ()=>{
    setIsPending(true)

    startTransition(async() => {
      setTimeout(() => {
        if(checkIfQuantityIsZero){
          addCartItem(item.variant!, item.merchandise.product)
        }else{
          optimisticUpdate(payload.merchandiseId, type);
        }
        setIsPending(false)
      }, 800)
      const message = await updateItemQuantity(null, payload)
      setMessage(message)
      router.refresh()
    })
  }   
  const disabled = (checkIfQuantityIsZero && type === 'minus') || isPending
  return (
    <div className={cn('', {'loading': isPending})}
    >
      <SubmitButton type={type} onClick={addToCartTransition} disabled={disabled}/>
      <p aria-live="polite" className="sr-only" role="status">
        {message}
      </p>
    </div>
  );
}

