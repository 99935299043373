
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"0dac2bf840a3f6a196b00b2355d122992101bcd1":"createCartAndSetCookie","19c80438ebd87a438cd1c49fa0ef01f216022761":"redirectToCheckout","2bfa47e23b889c28b67c7a01a0cbd0bc976a3b84":"removeItem","6d0aaadc49da97f522b20dd91099574cc0173320":"restartCartItems","ab5078384b01b9f93de70e10f26924588feba7ea":"addItem","e155dd017ce168316822156b9b155436e6508618":"updateItemQuantity"} */ export var restartCartItems = createServerReference("6d0aaadc49da97f522b20dd91099574cc0173320");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var addItem = createServerReference("ab5078384b01b9f93de70e10f26924588feba7ea");
export var removeItem = createServerReference("2bfa47e23b889c28b67c7a01a0cbd0bc976a3b84");
export var updateItemQuantity = createServerReference("e155dd017ce168316822156b9b155436e6508618");
export var redirectToCheckout = createServerReference("19c80438ebd87a438cd1c49fa0ef01f216022761");
export var createCartAndSetCookie = createServerReference("0dac2bf840a3f6a196b00b2355d122992101bcd1");

